import JSZip from "jszip";
import {InboxItemDto, MessageMultimediaDto} from "../../services/openapi";
import {Inbox} from "../types/Inbox";
import {Multimedia} from "../types/Multimedia";
import saveAs from "file-saver/src/FileSaver";

export function transformDtoToInbox(inboxdto: InboxItemDto): Inbox | undefined {
    if (inboxdto === undefined)
        return undefined;

    const inbox: Inbox = {
        from: inboxdto.from,
        to: inboxdto.to,
        subject: inboxdto.subject,
        received: inboxdto.received,
        messageText: inboxdto?.messageTexts?.find(k => k.type === "text/plain; charset=utf-8")?.lcontent,
        media: (inboxdto.messageMultimediaList || []).map(transformDtoToMultiMedia).filter(Boolean) as Multimedia[],
    };

    return inbox;
}

function transformDtoToMultiMedia(multiMedia: MessageMultimediaDto): Multimedia | undefined {
    if (!multiMedia) {
        return undefined;
    }

    return {
        multimediaId: multiMedia.multimediaId,
        name: multiMedia.name,
        type: multiMedia.type,
        content: multiMedia.content,
        size: multiMedia.size,
        base64content: `data:${multiMedia.type};base64,${multiMedia.content}`,
    };
}

export function downloadSingleMedia(multimedia: Multimedia): void {
    if (!multimedia.content) {
        console.error('No content available for download.');
        return;
    }
    const byteCharacters = atob(multimedia.content);
    const byteNumbers = new Array(byteCharacters.length);

    for (let i = 0; i < byteCharacters.length; i++) {
        byteNumbers[i] = byteCharacters.charCodeAt(i);
    }

    const byteArray = new Uint8Array(byteNumbers);
    const blob = new Blob([byteArray], {type: multimedia.type || 'application/octet-stream'});

    // Create a download link
    const downloadLink = document.createElement('a');
    downloadLink.href = URL.createObjectURL(blob);
    downloadLink.download = multimedia.name || 'download'; // Set the default file name

    // Append the link to the body and click it programmatically
    document.body.appendChild(downloadLink);
    downloadLink.click();

    // Clean up
    document.body.removeChild(downloadLink);
}

export function downloadMultipleMediaZip(media: Multimedia[] | undefined): void {
    if (!media) {
        return;
    }

    const zip = new JSZip();
    let map = new Map<string, number>();

    media.forEach((multimedia, index) => {
        if (multimedia.content) {
            const byteCharacters = atob(multimedia.content);
            const byteNumbers = new Array(byteCharacters.length);

            for (let i = 0; i < byteCharacters.length; i++) {
                byteNumbers[i] = byteCharacters.charCodeAt(i);
            }

            const byteArray = new Uint8Array(byteNumbers);
            let fileName = multimedia.name || `multimedia_${index}`;

            // Create a unique filename for each multimedia
            if (multimedia.name && map.has(multimedia.name)) {
                let count = map.get(multimedia.name) ?? 0;
                count++;
                map.set(multimedia.name, count);
                const indexOfLastDot = fileName.lastIndexOf('.');
                fileName = fileName.slice(0, indexOfLastDot) + '_' + count + '.' + fileName.slice(indexOfLastDot + 1);
            } else if (multimedia.name) {
                map.set(multimedia.name, 1);
            }
            zip.file(fileName, byteArray);
        }
    });

    // Create a zip file
    zip.generateAsync({type: 'blob'}).then((blob) => {
        // Save the zip file
        saveAs(blob, 'multimedia.zip');
    });
}

export function isImage(media: Multimedia | undefined): boolean {
    return media?.type?.startsWith('image/') ?? false;
}

export function isVideoOrAudio(media: Multimedia | undefined): boolean {
    return (media?.type === 'video/mp4' || media?.type === 'audio/aac'
        || media?.type === 'audio/mp3' || media?.type === 'audio/mp4'
        || media?.type === 'audio/ogg') ?? false;
}