import {Card, CardActionArea, CardActions, CardContent, CardMedia, Typography} from "@mui/material";
import {useTranslation} from "react-i18next";
import DownloadButton from "./DownloadButton";
import {Multimedia} from "../types/Multimedia";
import {isImage, isVideoOrAudio} from "../services/MultimediaService";
import React from "react";
import InsertDriveFileIcon from "@mui/icons-material/InsertDriveFile";
import VideoFileIcon from '@mui/icons-material/VideoFile';
import AudioFileIcon from '@mui/icons-material/AudioFile';

interface FileCardProps {
    mediaContent: Multimedia | undefined;
    onClick: () => void;
}

function FileCard(props: FileCardProps) {
    const {mediaContent, onClick} = props;
    const {t} = useTranslation();
    const title = mediaContent?.name;

    return (
        <Card sx={{maxWidth: 345}}>
            <CardActionArea onClick={onClick} data-testid="card-action-area-1">
                {isImage(mediaContent) ? (
                    <CardMedia
                        component="img"
                        sx={{height: 140}}
                        src={mediaContent?.base64content}
                        data-testid="card-media-image"
                    />
                ) : isVideoOrAudio(mediaContent) ? (
                    <div style={{position: 'relative'}} data-testid="card-action-area-2">
                        <div
                            style={{
                                position: 'absolute',
                                top: 0,
                                left: 0,
                                width: '100%',
                                height: '100%',
                                zIndex: 1,
                            }}
                            onClick={onClick}
                        />
                        <CardMedia
                            frameBorder="0"
                            component="iframe"
                            src={mediaContent?.base64content}
                            data-testid="card-media-video-audio"
                        />
                    </div>
                ) : (
                    <CardContent sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                    }}>
                        {getIconForFileType(mediaContent?.type)}
                    </CardContent>
                )}
                <CardContent sx={{wordWrap: "break-word"}} data-testid="media-name">
                    <Typography variant="h6">{title}</Typography>
                </CardContent>
            </CardActionArea>
            <CardActions>
                <DownloadButton title={''} mediaToDownload={mediaContent} text={t('download-button-text')}/>
            </CardActions>
        </Card>
    );
}

export default FileCard;

export function getIconForFileType(fileType: string | undefined) {
    if (fileType?.startsWith('video/')) {
        return <VideoFileIcon sx={{fontSize: 140, color: '#babdc8'}} data-testid="video-icon"/>;
    }
    if (fileType?.startsWith('audio/')) {
        return <AudioFileIcon sx={{fontSize: 140, color: '#babdc8'}} data-testid="audio-icon"/>;
    }
    return <InsertDriveFileIcon sx={{fontSize: 140, color: '#babdc8'}} data-testid="file-icon"/>;
}