import {FormControl, InputAdornment, TextField} from '@mui/material';
import React, {ReactNode} from 'react';
import {InputBaseProps} from "@mui/material/InputBase";

type Props = {
    disabled?: boolean,
    label?: ReactNode,
    value?: string | null,
    onChange: (val: string) => void,
    variant?: "outlined" | "filled" | "standard",
    isPassword?: boolean,
    icon?: any,
    onKeyDown?: (event: React.KeyboardEvent) => void,
    error?: boolean;
    inputProps?: InputBaseProps['inputProps'];
}

function StandardTextField(props: Props) {
    const {label, icon, value, onChange, isPassword, variant, inputProps, ...otherProps} = props;

    return (
        <FormControl variant={variant ?? 'outlined'} sx={{width: '100%'}}>
            <TextField
                {...otherProps}
                fullWidth
                margin="normal"
                type={isPassword ? 'password' : 'text'}
                label={label}
                onChange={(e) => {
                    if (props.onChange) props.onChange(e.target.value);
                }}
                onKeyDown={props.onKeyDown}
                InputProps={{
                    startAdornment: (
                        <InputAdornment position="start">
                            {icon}
                        </InputAdornment>
                    ),
                }}
                inputProps={props.inputProps}
                error={props.error}
            />
        </FormControl>
    );
}

export default StandardTextField;
