import { createContext, ReactNode, useRef, useState } from "react";
import { InboxItemDto } from "../services/openapi";
import { useTranslation } from "react-i18next";
import { LanguageEnum } from "../locales/LanguageEnum";
import { Inbox } from "../Dashboard/types/Inbox";
import { downloadMultipleMediaZip, downloadSingleMedia, transformDtoToInbox } from "../Dashboard/services/MultimediaService";
import { Multimedia } from "../Dashboard/types/Multimedia";

type UserContextType = {
  owner: string | undefined;
  from: string | undefined;
  numberOfFiles: () => number;
  date: () => string | undefined;
  mmsText: () => string | undefined;
  containsText: () => boolean;
  login: (inbox: InboxItemDto) => void;
  logout: () => void;
  media: () => Array<Multimedia>;
  findMediaById: (id: number) => Multimedia | undefined;
  isLogged: boolean;
  changeLanguage: () => void;
  language: LanguageEnum;
  downloadAll: () => void;
  downloadById: (id: number) => void;
};

export const UserContext = createContext<UserContextType | undefined>(undefined);

type UserContextProviderProps = {
  children: ReactNode;
  isLogged: boolean;
  setIsLogged: (value: boolean) => void;
};

export type media = {
  multimediaId: number,
  fullName: string,
  name: string,
  type: string,
  size: number,
  lastChange: string,
  imgSrc: string,
}

const LOGOUT_INTERVAL_MINUTES = 10; // set logout period

function UserContextProvider(props: UserContextProviderProps) {
  //#region attribues, states

  // eslint-disable-next-line
  const [t, i18n] = useTranslation('common');
  const [inbox, setInbox] = useState<Inbox | undefined>(undefined);
  const [language, setLanguage] = useState<LanguageEnum>(LanguageEnum.DE);
  const timerRef = useRef<NodeJS.Timeout | undefined>(undefined);
  //#endregion

  //#region internal timer
  const clearTimer = () => {
    if (timerRef.current) {
      clearTimeout(timerRef.current);
    }
    timerRef.current = undefined
  }

  const beginTimer = () => {
    timerRef.current = setTimeout(() => {
      logout(); // Logout user after inactivity
    }, LOGOUT_INTERVAL_MINUTES * 60 * 1000); // LOGOUT_INTERVAL_MINUTES minutes in milliseconds
  }

  const resetTimer = () => {
    if (isLogged()) {
      if (timerRef.current) {
        clearTimeout(timerRef.current);
      }
      beginTimer();
    }
  };
  //#endregion


  //#region export functions
  function login(inbox: InboxItemDto) {
    props.setIsLogged(true);
    beginTimer();
    setInbox(transformDtoToInbox(inbox));
  }

  function logout() {
    props.setIsLogged(false);
    clearTimer();
    setInbox(undefined);
  }

  function date(): string | undefined {
    resetTimer();
    return transformDate(inbox?.received);
  }

  function numberOfFiles(): number {
    resetTimer();
    return (inbox && inbox.media) ? inbox.media.length : 0;
  }

  function mmsText(): string | undefined {
    resetTimer();
    return inbox?.messageText;
  }

  function containsText(): boolean {
    resetTimer();
    let text = mmsText();
    return (text !== undefined && text.length > 0);
  }

  function transformDate(dateString?: string): string | undefined {
    if (dateString) {
      const parts = dateString.split('-');
      if (parts.length === 3) {
        const [year, month, day] = parts;
        return `${day}.${month}.${year}`;
      }
    }
    return undefined;
  }

  function media(): Array<Multimedia> {
    resetTimer();
    return inbox?.media ?? [];
  }

  function findMediaById(id: number): Multimedia | undefined {
    resetTimer();
    return inbox?.media?.find(k => k.multimediaId === id);
  }

  function isLogged(): boolean {
    return props.isLogged;
  }

  function changeLanguage(): void {
    resetTimer();
    const newLang = language === LanguageEnum.DE ? LanguageEnum.EN : LanguageEnum.DE;
    i18n.changeLanguage(newLang);
    setLanguage(newLang);
  }

  function downloadById(id: number): void {
    resetTimer();
    const media = findMediaById(id);
    if (media) {
      downloadSingleMedia(media);
    }
  }

  function downloadAll(): void {
    downloadMultipleMediaZip(media());
  }
  //#endregion

  return (
    <UserContext.Provider value={
      {
        owner: inbox?.to,
        from: inbox?.from,
        date,
        login,
        logout,
        numberOfFiles,
        mmsText,
        containsText,
        findMediaById,
        media,
        isLogged: isLogged(),
        changeLanguage,
        language,
        downloadAll,
        downloadById
      }}>
      {props.children}
    </UserContext.Provider>
  );
}

export default UserContextProvider;
