import Toolbar from "@mui/material/Toolbar";
import {Tooltip, Typography} from "@mui/material";
import React, {useContext} from "react";
import PersonIcon from "@mui/icons-material/Person";
import IconButton from "@mui/material/IconButton";
import LogoutIcon from "@mui/icons-material/Logout";
import {UserContext} from "../contexts/UserContext";
import {useNavigate} from "react-router-dom";
import {LanguageEnum} from "../locales/LanguageEnum";
import GermanFlag from "../locales/flags/GermanFlag";
import BritishFlag from "../locales/flags/BritishFlag";
import {useTranslation} from "react-i18next";

function LogoToolbar() {
    const context = useContext(UserContext);
    const navigate = useNavigate();
    const {t} = useTranslation();
    const handleLogout = () => {
        context?.logout();
        navigate("/");
    };

    return (
        <Toolbar sx={{borderBottom: 1, borderColor: 'primary.light', marginBottom: '50px'}}
                 data-testid="logo-toolbar">
            <div style={{display: 'flex', alignItems: 'center'}}>
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="30px"
                     data-testid="logo-toolbar-image">
                    <g fill="#0019A5">
                        <path xmlns="http://www.w3.org/2000/svg" fillRule="evenodd"
                              d="M0 10.071C0 7.26.862 4.95 2.586 3.141 4.311 1.333 6.58.429 9.392.429c2.779 0 5.03.904 6.755 2.712 1.724 1.808 2.586 4.118 2.586 6.93 0 2.679-.879 4.956-2.636 6.83-1.758 1.876-3.993 2.813-6.705 2.813-2.746 0-4.997-.937-6.755-2.812C.879 15.027 0 12.75 0 10.072zm3.616 0c0 1.842.494 3.45 1.482 4.822.987 1.373 2.419 2.059 4.294 2.059 1.841 0 3.256-.686 4.244-2.06.987-1.372 1.481-2.98 1.481-4.82 0-1.842-.519-3.45-1.557-4.822s-2.427-2.06-4.168-2.06-3.14.687-4.194 2.06c-1.055 1.373-1.582 2.98-1.582 4.821zm20.19 13.26h-5.424v-1.407l.628-.552c.418-.369.744-.678.979-.93.234-.25.519-.577.854-.979a7.17 7.17 0 00.778-1.105c.184-.335.276-.653.276-.954a.955.955 0 00-.401-.804 1.47 1.47 0 00-.904-.3c-.603 0-1.24.217-1.909.652l-.1-1.356a5.314 5.314 0 012.41-.552c1.708 0 2.562.786 2.562 2.36 0 .536-.201 1.122-.603 1.758s-.737 1.096-1.004 1.381c-.268.285-.754.778-1.457 1.482h3.315z"></path>

                    </g>
                </svg>
                <Typography variant="caption" color={'secondary.main'} sx={{p: 3}}>Communication Center</Typography>
            </div>
            <div style={{display: 'flex', alignItems: 'center', marginLeft: 'auto'}}>
                {<Tooltip title={t("changeLanguage")}>
                    <IconButton onClick={context?.changeLanguage} color="inherit" data-testid="change-language-btn">
                        {context?.language === LanguageEnum.DE ? <GermanFlag/> : <BritishFlag/>}
                    </IconButton>
                </Tooltip>}
                {context?.isLogged && <>
                    <PersonIcon sx={{fontSize: 24}} color={'secondary'}/>
                    <Typography variant="overline" color={'secondary.main'}>{context?.owner ?? ""}</Typography>
                    <Tooltip title={t('toolbar-logout-tooltip')}>
                        <IconButton
                            size="large"
                            aria-label="logout"
                            edge="end"
                            color="secondary"
                            onClick={handleLogout}
                            data-testid="log-out-btn"
                        >
                            <LogoutIcon/>
                        </IconButton>
                    </Tooltip>
                </>}
            </div>
        </Toolbar>
    );
};

export default LogoToolbar;