import {IconButton, Tooltip} from "@mui/material";
import CloudDownloadIcon from "@mui/icons-material/CloudDownload";
import Typography from "@mui/material/Typography";
import { Multimedia } from "../types/Multimedia";
import { downloadSingleMedia } from "../services/MultimediaService";

interface DownloadButtonProps {
    title: string;
    text: string;
    mediaToDownload: Multimedia | undefined;
}

function DownloadButton(props: DownloadButtonProps) {
    const {title, text, mediaToDownload} = props

    const handleDownload = () => {
        if (mediaToDownload) {
            downloadSingleMedia(mediaToDownload);
        }
    }

    return (<Tooltip title={title}>
        <IconButton color="primary" onClick={handleDownload}>
            <CloudDownloadIcon sx={{marginLeft: "15%", marginRight: "15%"}}/>
            <Typography variant="body2"> {text}</Typography>
        </IconButton>
    </Tooltip>);
}

export default DownloadButton;