import {Box, CardContent, CardMedia, Modal, Typography} from "@mui/material";
import {useTranslation} from "react-i18next";
import DownloadButton from "./DownloadButton";
import {Multimedia} from "../types/Multimedia";
import {isImage, isVideoOrAudio} from "../services/MultimediaService";
import {getIconForFileType} from "./FileCard";

interface FileModalProps {
    mediaContent: Multimedia | undefined;
    onClose: () => void;
}

const style = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: "auto",
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
    maxWidth: '80vw',
    maxHeight: '80vh',
    overflowY: 'auto',
};

function FileModal(props: FileModalProps) {
    const {mediaContent, onClose} = props
    const {t} = useTranslation();

    return (
        <Modal
            open={mediaContent !== undefined}
            keepMounted
            onClose={onClose}
            aria-labelledby="media-modal"
            aria-describedby="media-modal"
        >
            <Box sx={style}>
                <Typography variant="h6" align="center" data-testid="file-modal-name">
                    {mediaContent?.name}
                </Typography>
                {isImage(mediaContent) ? (
                    <CardMedia
                        component="img"
                        height="auto"
                        width="auto"
                        src={mediaContent?.base64content}
                        alt={mediaContent?.name}
                        sx={{maxWidth: '40vw', maxHeight: '50vh'}}
                        data-testid="file-modal-image"
                    />
                ) : isVideoOrAudio(mediaContent) ? (
                    <Box sx={{position: 'relative', overflow: 'hidden', maxWidth: '98%', maxHeight: '50vh'}}>
                        <CardMedia
                            component="iframe"
                            height="auto"
                            width="98%"
                            src={mediaContent?.base64content}
                            sx={{minWidth: '40vw', minHeight: '50vh'}}
                        />
                    </Box>
                ) : (
                    <CardContent sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                    }}>
                        {getIconForFileType(mediaContent?.type)}
                    </CardContent>
                )}
                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        marginTop: 2,
                    }}
                >
                    <Typography variant="body2" data-testid="file-modal-desc">
                        {t('modal-type-label')}: {mediaContent?.type} | {t('modal-size-label')}: {mediaContent?.size}
                    </Typography>
                    <DownloadButton title={t('modal-download-tooltip')} mediaToDownload={mediaContent} text={''}/>
                </Box>
            </Box>
        </Modal>
    );
}

export default FileModal;