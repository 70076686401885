import React, {useContext, useState} from 'react';
import {Alert, Button, Grid, Paper, Typography} from '@mui/material';
import StandardTextField from '../MUIComponents/StandardTextField';
import {AccountCircle} from '@mui/icons-material';
import {useNavigate} from 'react-router-dom';
import KeyIcon from '@mui/icons-material/Key';
import {UserContext} from '../contexts/UserContext';
import {useTranslation} from "react-i18next";
import {InboxItemDto, UserControllerService} from '../services/openapi';

const LoginPage = () => {
    const {t} = useTranslation();
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const navigate = useNavigate();
    const context = useContext(UserContext);
    const [errorText, setErrorText] = useState('');


    const handleLogin = () => {
        UserControllerService.authenticate({
            username: username,
            password: password,
        }).then((val: InboxItemDto) => {
            context?.login(val);
            navigate("dashboard");
            setErrorText('');
        }).catch((error) => {
            handleError(error);
        })
    };

    const handleKeyPress = (event: React.KeyboardEvent) => {
        if (event.key === 'Enter') {
            handleLogin();
        }
    };

    const handleError = (error: any) => {
        if (error && error.status === 403) {
            setErrorText(t('login-error-unauthorized'))
        } else if (error && error.status === 429) {
            setErrorText(t('login-error-too-many-requests'))
        } else {
            setErrorText(t('login-error-general'));
        }
    }

    return (
        <Grid container sx={{justifyContent: 'center', marginTop: '100px'}} spacing={2}>
            <Grid item xs={5}>
                <Paper elevation={3} style={{padding: '20px',}}>
                    <Typography variant="h5">{t('login-form-heading')}</Typography>
                    {errorText && (
                        <Alert severity="error" sx={{marginTop: 1, marginBottom: 1}}
                               data-testid="login-error-msg">
                            {errorText}
                        </Alert>
                    )}
                    <StandardTextField
                        label={t('login-username-label')}
                        value={username}
                        onChange={(username: string) => {
                            setUsername(username)
                        }}
                        icon={<AccountCircle sx={{color: 'action.active', mr: 1, my: 0.5}}/>}
                        onKeyDown={handleKeyPress}
                        error={Boolean(errorText)}
                        inputProps={{"data-testid": "login-username-input"}}
                    />
                    <StandardTextField
                        label={t('login-password-label')}
                        isPassword
                        value={password}
                        onChange={(password: string) => {
                            setPassword(password)
                        }}
                        icon={<KeyIcon sx={{color: 'action.active', mr: 1, my: 0.5}}/>}
                        onKeyDown={handleKeyPress}
                        error={Boolean(errorText)}
                        inputProps={{"data-testid": "login-password-input"}}
                    />
                    <Button
                        variant="contained"
                        color="secondary"
                        fullWidth
                        onClick={handleLogin}
                        data-testid="login-submit-btn"
                    >
                        {t('login-form-btn')}
                    </Button>
                </Paper>
            </Grid>
            <Grid item xs={5}>
                <Grid container style={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'flex-start',
                    justifyContent: 'center'
                }}>
                    <Typography variant="h6" component="h2" sx={{display: 'flex', alignItems: 'center'}}>
                        {t('login-heading-text')}
                    </Typography>
                    <Typography variant="subtitle2" component="h2"
                                sx={{display: 'flex', textAlign: 'justify', marginTop: '10px'}}>
                        {t('login-description-text')}
                    </Typography>
                </Grid>
            </Grid>
        </Grid>
    );
};

export default LoginPage;