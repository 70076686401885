const locales_de = {
    'login-form-heading': 'Login',
    'login-form-btn': 'Login',
    'login-password-label': 'Kennwort',
    'login-username-label': 'Telefonnummer',
    'login-heading-text': 'Sie haben eine o2 MMS erhalten',
    'login-error-unauthorized': 'Ungültige Anmeldedaten. Bitte versuchen Sie es erneut.',
    'login-error-too-many-requests': 'Zu viele Versuche. Versuchen Sie es später erneut.',
    'login-error-general': 'Der Dienst ist derzeit nicht verfügbar. Versuchen Sie es später erneut.',
    'login-description-text': 'Bitte geben Sie Ihre Telefonnummer inklusive Vorwahl und das per SMS erhaltene Kennwort ein.',
    'dashboard-headline': 'Ihre MMS-Nachricht',
    'dashboard-absender': 'Absender',
    'dashboard-empfanger': 'Empfänger',
    'dashboard-datum': 'Datum (TT.MM.YYYY)',
    'dashboard-anzahl-dateien': 'Anzahl der Dataien',
    'dashboard-text': 'Text',
    'dashboard-no-text': 'Kein Text',
    'dashboard-copy-text': 'Kopieren in die Zwischenablage',
    'dashboard-download-zip': 'Alles herunterladen',
    'changeLanguage': 'Sprache ändern',
    'download-button-text': 'Herunterladen',
    'modal-download-tooltip': 'Herunterladen',
    'modal-size-label': 'Größe',
    'modal-type-label': 'Typ',
    'toolbar-logout-tooltip': 'Abmelden',
    'footer-contact': 'Kontakt',
    'footer-imprint': 'Impressum',
    'footer-agb-info': 'AGB & Pflichtinformationen',
    'footer-data-protection-info': 'Datenschutz'
}
export default locales_de;