import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import i18next from "i18next";
import {initReactI18next} from "react-i18next";
import locales_de from "./locales/de";
import locales_en from "./locales/en";

i18next.use(initReactI18next).init({
    interpolation: {escapeValue: false},
    debug: true,
    fallbackLng: 'de',
    lng: 'de',
    resources: {
        de: {translation: locales_de},
        en: {translation: locales_en},
    }
});

const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement
);
root.render(
    <React.StrictMode>
        <App/>
    </React.StrictMode>
);
