import Box from "@mui/material/Box";
import {Container, Divider, Link, Typography} from "@mui/material";
import IconButton from "@mui/material/IconButton";
import {useTranslation} from "react-i18next";

const Footer = () => {
    const {t} = useTranslation();

    const footerStyle = {
        width: 'auto',
        marginTop: '20px',
    };

    const footerItems = [
        {label: t('footer-contact'), link: 'https://www.o2online.de/kontakt/'},
        {label: t('footer-imprint'), link: 'https://www.o2online.de/recht/impressum/'},
        {label: t('footer-agb-info'), link: 'https://www.o2online.de/recht/agb-und-infos/'},
        {label: t('footer-data-protection-info'), link: 'https://www.o2online.de/recht/datenschutz/'},
    ];

    const telefonicaImageSrc = "footer-telefonica-logo.png";
    const telefonicaLink = "https://www.telefonica.de/";

    return (
        <Box component="footer" sx={footerStyle} data-testid="page-footer">
            <Divider sx={{borderTop: '1px solid white', mt: 1, mb: 2}}/>
            <Container sx={containerStyle}>
                <Box sx={infoContainer}>
                    {footerItems.map((item, index) => (
                        <Typography key={index} variant="body2">
                            <Link href={item.link} underline="none" target="_blank">
                                {item.label}
                            </Link>
                        </Typography>
                    ))}
                </Box>
                <Box sx={infoContainer}>
                    <Typography variant="body2" sx={{marginTop: '15px'}}>
                        <Link href={telefonicaLink} underline="none" color='secondary.main'
                              style={{fontSize: 'smaller'}} target="_blank">
                            © Telefónica Germany GmbH & Co. OHG
                        </Link>
                    </Typography>
                    <IconButton sx={{textDecoration: 'none', '&:hover': {backgroundColor: 'transparent'}}}>
                        <Link href={telefonicaLink} underline="none" color='secondary.main'
                              style={{fontSize: 'smaller'}} target="_blank">
                            <img src={telefonicaImageSrc} title="Telefónica" alt="Telefónica" height="34"/>
                        </Link>
                    </IconButton>
                </Box>
            </Container>
        </Box>
    );
};


const containerStyle = {
    display: 'flex',
    flexDirection: 'column', // Arrange items in a column
    alignItems: 'center',
};

const infoContainer = {
    display: 'flex',
    flexDirection: 'row',
    gap: 4,
    paddingBottom: 2,
};

const socialIconsContainer = {
    display: 'flex',
    alignItems: 'center',
    gap: 2,
    fontSize: '50px'
};

export default Footer;

