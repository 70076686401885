import LogoToolbar from "../common/LogoToolbar";
import React from "react";
import {Container} from "@mui/material";
import Footer from "../common/Footer";
import {Outlet} from "react-router-dom";

export default function Layout() {
    return (
        <Container maxWidth='md' sx={{minWidth: '720px'}}>
            <LogoToolbar/>
            <Outlet/>
            <Footer/>
        </Container>);
}
