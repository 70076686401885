const locales_en = {
    'login-form-heading': 'Login',
    'login-form-btn': 'Login',
    'login-password-label': 'Password',
    'login-username-label': 'Phone number',
    'login-heading-text': 'You received an o2 MMS',
    'login-error-unauthorized': 'Invalid credentials. Please try again.',
    'login-error-too-many-requests': 'Too many login attempts. Please try again later.',
    'login-error-general': 'Service is currently unavailable. Please try again later.',
    'login-description-text': 'Please enter your phone number and the password you received via SMS.',
    'dashboard-headline': 'Your MMS Message',
    'dashboard-absender': 'Sender',
    'dashboard-empfanger': 'Receiver',
    'dashboard-datum': 'Date (DD.MM.YYYY)',
    'dashboard-anzahl-dateien': 'Number of files',
    'dashboard-text': 'Text',
    'dashboard-no-text': 'No text',
    'dashboard-copy-text': 'Copy text to clipboard',
    'dashboard-download-zip': 'Download everything',
    'changeLanguage': 'change language',
    'download-button-text': 'Download',
    'modal-download-tooltip': 'Download',
    'modal-size-label': 'Size',
    'modal-type-label': 'Type',
    'toolbar-logout-tooltip': 'Logout',
    'footer-contact': 'Contact',
    'footer-imprint': 'Imprint',
    'footer-agb-info': 'General Business Conditions and required information',
    'footer-data-protection-info': 'Data protection',
}
export default locales_en;