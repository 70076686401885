import {createBrowserRouter, createRoutesFromElements, Route, RouterProvider, useNavigate} from "react-router-dom";
import LoginPage from "./LoginPage/Loginpage";
import DashboardLayout from "./Dashboard/DashBoard";
import UserContextProvider from "./contexts/UserContext";
import {createTheme, ThemeProvider} from "@mui/material";
import React, {useEffect, useState} from "react";
import Layout from "./Layout/Layout";

const NotFound = () => {
    const navigate = useNavigate();

    useEffect(() => {
        // Redirecting to the login page when the route doesn't match
        navigate('/');
    }, [navigate]);

    return null;
};

function App() {
    const theme = createTheme({
        palette: {
            primary: {
                main: "#58617a",
                light: "#babdc8"
            },
            secondary: {
                main: "#0019A5"
            }
        },
        typography: {
            "fontFamily": `"Verdana", "Arial", "helvetica", sans-serif`,
            "fontSize": 14,
            allVariants: {
                "color": "#58617a",
            },
            caption: {
                fontSize: 16,
            }
        }
    });

    const [logged, setLogged] = useState<boolean>(false);

    const router = createBrowserRouter(
        createRoutesFromElements(
            <Route element={<Layout/>}>
                <Route index element={<LoginPage/>}/>
                <Route path="dashboard" element={<DashboardLayout isLogged={logged}/>}/>
                <Route path="*" element={<NotFound/>}/>
            </Route>
        )
    )

    return (
        <UserContextProvider isLogged={logged} setIsLogged={setLogged}>
            <ThemeProvider theme={theme}>
                <RouterProvider router={router}/>
            </ThemeProvider>
        </UserContextProvider>
    );
}

export default App;
