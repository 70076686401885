import Typography from '@mui/material/Typography';
import {UserContext} from '../contexts/UserContext';
import React, {useCallback, useContext, useEffect, useState} from 'react';
import {Container, Divider, Grid, IconButton, TextField, Tooltip} from '@mui/material';
import {useTranslation} from "react-i18next";
import FileCard from './components/FileCard';
import FileModal from './components/FileModal';
import {ContentCopy} from "@mui/icons-material";
import {MessageMultimediaDto} from "../services/openapi";
import FolderZipIcon from '@mui/icons-material/FolderZip';
import VerticalAlignTopIcon from '@mui/icons-material/VerticalAlignTop';
import {useNavigate} from 'react-router-dom';

type DashBoardProps = {
    isLogged: boolean;
};

export default function DashBoard({isLogged}: DashBoardProps) {

    const context = useContext(UserContext);
    const [mediaToOpen, setMediaToOpen] = useState<MessageMultimediaDto | undefined>(undefined);
    const [showToTopBtn, setShowToTopBtn] = useState(false);

    const navigate = useNavigate();

    const dashboardLogout = useCallback(() => {
        context?.logout();
        navigate('/');
    }, [context, navigate]);

    useEffect(() => {
        if (isLogged === false) {
            dashboardLogout();
        }
    }, [isLogged, dashboardLogout]);

    useEffect(() => {
        window.addEventListener('scroll', () => {
            window.onscroll = () => {
                if (window.scrollY === 0) {
                    setShowToTopBtn(false);
                } else {
                    setShowToTopBtn(true);
                }
            };
        });
    }, []);

    const {t} = useTranslation();
    const gridForTextSize = context?.containsText() ? 11 : 12;

    const handleOpen = (id: number | undefined) => {
        if (!id || !context?.isLogged) {
            return;
        }
        const newMedia = context?.findMediaById(id);
        if (newMedia) {
            setMediaToOpen(newMedia);
        }
    }

    const handleClose = () => {
        setMediaToOpen(undefined);
    }

    const copyText = () => {
        const textToCopy = context?.mmsText();
        if (textToCopy) {
            navigator.clipboard.writeText(textToCopy);
        }
    }

    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        });
    };

    const medias = context?.media();

    return (
        <>
            <Container maxWidth="md" key="dashboard-container" sx={{textAlign: 'center'}}>
                <Grid container alignItems="center" justifyContent="space-between" key="dashboard-grid-container">
                    <Grid item key="dashboard-header-text-grid-item">
                        <Typography variant="h5" gutterBottom>
                            {t('dashboard-headline')}
                        </Typography>
                    </Grid>
                    <Grid item key="dashboard-header-downloadicon-grid-item">
                        <Tooltip title={t('dashboard-download-zip')}>
                            <IconButton
                                size="large"
                                aria-label="content-copy"
                                edge="end"
                                color='primary'
                                onClick={context?.downloadAll}
                            >
                                <FolderZipIcon/>
                            </IconButton>
                        </Tooltip>
                    </Grid>

                </Grid>
                <Divider sx={{marginBottom: "10px"}}/>
                <Grid container spacing={2}>
                    <Grid item xs={6}>
                        <TextField
                            label={t('dashboard-absender')}
                            variant="outlined"
                            fullWidth
                            value={context?.from ?? ''}
                            disabled
                            data-testid="text-field-sender"
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <TextField
                            label={t('dashboard-empfanger')}
                            variant="outlined"
                            fullWidth
                            value={context?.owner ?? ''}
                            disabled
                            data-testid="text-field-receiver"
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <TextField
                            label={t('dashboard-datum')}
                            variant="outlined"
                            fullWidth
                            value={context?.date() ?? ''}
                            disabled
                            data-testid="text-field-date"
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <TextField
                            label={t('dashboard-anzahl-dateien')}
                            variant="outlined"
                            fullWidth
                            value={context?.numberOfFiles() ?? ''}
                            disabled
                            data-testid="text-field-files"
                        />
                    </Grid>

                    <Grid item xs={gridForTextSize}>
                        <TextField
                            label={t('dashboard-text')}
                            variant="outlined"
                            fullWidth
                            value={context?.mmsText() ?? ''}
                            disabled
                            multiline
                            data-testid="text-field-message"
                        />
                    </Grid>
                    {context?.containsText() &&
                        <Grid item xs={1}>
                            <Tooltip title={t('dashboard-copy-text')}>
                                <IconButton
                                    size="large"
                                    aria-label="content-copy"
                                    edge="end"
                                    color='primary'
                                    onClick={copyText}
                                > <ContentCopy/>
                                </IconButton>
                            </Tooltip>
                        </Grid>
                    }
                </Grid>
                <Grid container spacing={2} marginTop={'20px'}>
                    {medias?.map(obr => <Grid item xs={4} sx={{width: '100%'}} key={"media_" + obr.multimediaId}>
                            <FileCard mediaContent={obr} onClick={() => handleOpen(obr.multimediaId)}
                                      key={obr.multimediaId + "fileCard"}/>
                        </Grid>
                    )}
                </Grid>
                {showToTopBtn &&
                    <VerticalAlignTopIcon onClick={scrollToTop} color='primary'
                                          sx={{cursor: 'pointer', marginTop: '35px'}}/>
                }
            </Container>
            <FileModal mediaContent={mediaToOpen} onClose={handleClose}/>
        </>);
}